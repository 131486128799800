<template>
  <el-container style="height: 100%;overflow-y: auto">
    <el-header style="height: 15%">
      <el-descriptions class="margin-top" title="单据影像信息" :column="3" size="small" border style="margin-bottom: 0">
        <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            单据号码
          </template>
          {{ task.taskNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            扫描时间
          </template>
          {{ task.createTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            扫描人
          </template>
          {{ task.createBy }}
        </el-descriptions-item>
      </el-descriptions>
    </el-header>
    <el-container style="height: 85% !important;">
      <el-aside style="width: 60%;height: 100%">
        <el-container style="height: 100%">
          <el-header style="height: 10%;text-align: left">
            <el-button type="primary" icon="el-icon-left" @click="prevImg" size="small">上一页</el-button>
            <el-button type="primary" icon="el-icon-right" @click="nextImg" size="small">下一页</el-button>
            <el-button type="primary" icon="el-icon-right" @click="rotateLeft" size="small">左转90°</el-button>
            <el-button type="primary" icon="el-icon-right" @click="rotateRight" size="small">右转90°</el-button>
            <el-button type="primary" icon="el-icon-bottom" size="small">下载</el-button>
          </el-header>
          <el-main style="height: 77%" class="viewer-wrapper">
            <viewer class="viewer" :options="options" :images="imageList" @inited="inited" ref="viewer">
              <figure class="images">
                <div class="image-wrapper" v-for="img in imageList">
                  <img class="image" :src="img.preViewUrl" :data-source="img.preViewUrl" :alt="img.id"
                       style="display:none;"/>
                </div>
              </figure>
            </viewer>
          </el-main>
          <el-footer style="height: 18%">
            Footer
          </el-footer>
        </el-container>
      </el-aside>
      <el-main style="height: 100% !important;">
        <template v-if="invoiceList.length > 0">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="inv in invoiceList" :label="inv.invNumber" :name="inv.invNumber" :key="inv.id">
              <ImageInvoice :imageData="imageData" :invoiceId="inv.id"/>
            </el-tab-pane>
          </el-tabs>
        </template>
        <template v-else>
          <ImageInvoice :imageData="imageData"/>
        </template>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import request from '@/utils/request'
import 'viewerjs/dist/viewer.css'
import ImageInvoice from "./Image_Invoice";

export default {
  components: {
    ImageInvoice
  },
  props: {
    taskId: Number,
    findByPage: Function,
    dialog: Object,
    invoiceTypeList: Array,
    task: Object
  },
  data() {
    return {
      activeName: '',
      activeId: '',
      options: {
        inline: true,
        button: false,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: 'data-source',
      },
      invoiceList: [],
      invoice: {
        invNumber: '',
        invCode: '',
        invDate: '',
        invType: '',
        invAmount: '',
        invTax: '',
        invTotalAmount: '',
        invoiceAddress: '',
        companyName: '',
        companyTaxCode: '',
        vendorName: '',
        vendorTaxCode: '',
        invoiceDetailList: []
      },
      imageList: [],
      imageData: {

      }
    }
  },
  mounted() {
    request({
      url: '/invoice/edocImage/findByTaskId/' + this.taskId,
      method: 'post',
      contentType: 'application/json',
      baseURL: 'http://127.0.0.1:8092'
    }).then(result => {
      this.imageList = result.data

      this.findInvoiceById(result.data[0].id)
      this.imageData = result.data[0]

    })
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    onSubmit() {
    },
    nextImg() {
      this.$viewer.next()

      let wrapper = document.getElementsByClassName("viewer-wrapper")[0]
      let list = wrapper.getElementsByClassName("viewer-list")[0].childNodes
      for (var i = 0; i < list.length; i++) {
        if (list[i].className == 'viewer-active') {
          let v = document.getElementsByClassName("image-wrapper")[i]
          let id = v.getElementsByTagName("img")[0].alt

          this.imageData = this.imageList[i]
          this.findInvoiceById(id)
        }
      }
    },
    prevImg() {
      this.$viewer.prev()

      let wrapper = document.getElementsByClassName("viewer-wrapper")[0]
      let list = wrapper.getElementsByClassName("viewer-list")[0].childNodes
      for (var i = 0; i < list.length; i++) {
        if (list[i].className == 'viewer-active') {
          let v = document.getElementsByClassName("image-wrapper")[i]
          let id = v.getElementsByTagName("img")[0].alt

          this.imageData = this.imageList[i]
          this.findInvoiceById(id)
        }
      }
    },
    rotateLeft() {
      this.$viewer.rotateLeft()
    },
    rotateRight() {
      this.$viewer.rotateRight()
    },
    findInvoiceById(id) {
      return request({
        url: `/invoice/edocInvoice/findByEdocImageId/` + id,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8092'
      }).then(result => {
        if (result.data.length > 0) {
          this.invoiceList = result.data
          this.activeName = result.data[0].invNumber
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-descriptions__header {
  margin-bottom: 0 !important;
}

.el-header, .el-footer {
  /*background-color: #B3C0D1;*/
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  /*line-height: 160px;*/
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
