<template>
  <el-form ref="invoice" :model="invoice" style="overflow-y: auto;" label-width="110px" size="small">
    <el-form-item label="单据类型">
      <el-input v-model="imageData.imageType"></el-input>
    </el-form-item>
    <template v-if="imageData.imageType === '1002'">
      <template v-if="invoice.invType === '1' || invoice.invType === '4' || invoice.invType === '10'">
        <!-- 增值税发票 -->
        <el-form-item label="发票号码">
          <el-input v-model="invoice.invNumber"></el-input>
        </el-form-item>
        <el-form-item label="发票代码">
          <el-input v-model="invoice.invCode"></el-input>
        </el-form-item>
        <el-form-item label="发票日期" style="width: 100%">
          <el-date-picker
              v-model="invoice.invDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <!--<el-form-item label="发票类型">
          <el-input v-model="invoice.invType"></el-input>
        </el-form-item>-->
        <el-form-item label="发票金额">
          <el-input v-model="invoice.invAmount"></el-input>
        </el-form-item>
        <el-form-item label="发票税额">
          <el-input v-model="invoice.invTax"></el-input>
        </el-form-item>
        <el-form-item label="发票合计">
          <el-input v-model="invoice.invTotalAmount"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="invoice.companyName"></el-input>
        </el-form-item>
        <el-form-item label="公司税号">
          <el-input v-model="invoice.companyTaxCode"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称">
          <el-input v-model="invoice.vendorName"></el-input>
        </el-form-item>
        <el-form-item label="供应商税号">
          <el-input v-model="invoice.vendorTaxCode"></el-input>
        </el-form-item>
        <el-form-item label="供应商税号">
          <el-input v-model="invoice.vendorTaxCode"></el-input>
        </el-form-item>
        <el-form-item label="供应商税号">
          <el-input v-model="invoice.vendorTaxCode"></el-input>
        </el-form-item>
        <el-form-item label="供应商税号">
          <el-input v-model="invoice.vendorTaxCode"></el-input>
        </el-form-item>
      </template>
      <template v-else-if="invoice.invType === '10506'">
        <!-- 机票 -->
        <el-form-item label="乘机人姓名">
          <el-input v-model="invoice.companyName"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="invoice.companyTaxCode"></el-input>
        </el-form-item>
        <el-form-item label="电子客票号码">
          <el-input v-model="invoice.invNumber"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="invoice.invCheckCode"></el-input>
        </el-form-item>
        <el-form-item label="填开日期">
          <el-date-picker
              v-model="invoice.invDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="销售单位代号">
          <el-input v-model="invoice.vendorCode"></el-input>
        </el-form-item>
        <el-form-item label="填开单位">
          <el-input v-model="invoice.vendorName"></el-input>
        </el-form-item>
        <el-form-item label="票价">
          <el-input v-model="invoice.invAmount"></el-input>
        </el-form-item>
        <el-form-item label="税费">
          <el-input v-model="invoice.invTax"></el-input>
        </el-form-item>
        <el-form-item label="总额">
          <el-input v-model="invoice.invTotalAmount"></el-input>
        </el-form-item>
      </template>
      <template v-else-if="invoice.invType === '10500'">
        <!-- 出租车发票 -->
        <el-form-item label="发票号码">
          <el-input v-model="invoice.invNumber"></el-input>
        </el-form-item>
        <el-form-item label="发票代码">
          <el-input v-model="invoice.invCode"></el-input>
        </el-form-item>
        <el-form-item label="发票日期">
          <el-date-picker
              v-model="invoice.invDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="上车时间">
          <el-input v-model="invoice.invoiceDetailList[0].timeGetOn"></el-input>
        </el-form-item>
        <el-form-item label="下车时间">
          <el-input v-model="invoice.invoiceDetailList[0].timeGetOff"></el-input>
        </el-form-item>
        <el-form-item label="里程">
          <el-input v-model="invoice.invoiceDetailList[0].mileage"></el-input>
        </el-form-item>
        <el-form-item label="总金额">
          <el-input v-model="invoice.invTotalAmount"></el-input>
        </el-form-item>
        <el-form-item label="发票所在地">
          <el-input v-model="invoice.invoiceDetailList[0].place"></el-input>
        </el-form-item>
        <el-form-item label="省">
          <el-input v-model="invoice.invoiceDetailList[0].province"></el-input>
        </el-form-item>
        <el-form-item label="市">
          <el-input v-model="invoice.invoiceDetailList[0].city"></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="invoice.invoiceDetailList[0].licensePlate"></el-input>
        </el-form-item>
      </template>
      <template v-else-if="invoice.invType === '10503'">
        <!--火车票-->
        <el-form-item label="号码">
          <el-input v-model="invoice.invNumber"></el-input>
        </el-form-item>
        <el-form-item label="乘车日期">
          <el-date-picker
              v-model="invoice.invDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="乘车时间">
          <el-input v-model="invoice.invoiceDetailList[0].time"></el-input>
        </el-form-item>
        <el-form-item label="乘车人">
          <el-input v-model="invoice.companyName"></el-input>
        </el-form-item>
        <el-form-item label="上车车站">
          <el-input v-model="invoice.invoiceDetailList[0].stationGetOn"></el-input>
        </el-form-item>
        <el-form-item label="下车车站">
          <el-input v-model="invoice.invoiceDetailList[0].stationGetOff"></el-input>
        </el-form-item>
        <el-form-item label="车次">
          <el-input v-model="invoice.invoiceDetailList[0].trainNumber"></el-input>
        </el-form-item>
        <el-form-item label="座位类型">
          <el-input v-model="invoice.invoiceDetailList[0].seat"></el-input>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="invoice.invTotalAmount"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="invoice.companyTaxCode"></el-input>
        </el-form-item>
      </template>
      <template v-else-if="invoice.invType === '10507'">
        <!-- 过路费发票-->
        <el-form-item label="发票号码">
          <el-input v-model="invoice.invNumber"></el-input>
        </el-form-item>
        <el-form-item label="发票代码">
          <el-input v-model="invoice.invCode"></el-input>
        </el-form-item>
        <el-form-item label="发票日期">
          <el-date-picker
              v-model="invoice.invDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间">
          <el-input v-model="invoice.invoiceDetailList[0].time"></el-input>
        </el-form-item>
        <el-form-item label="入口">
          <el-input v-model="invoice.invoiceDetailList[0].entrance"></el-input>
        </el-form-item>
        <el-form-item label="出口">
          <el-input v-model="invoice.invoiceDetailList[0].wayOut"></el-input>
        </el-form-item>
        <el-form-item label="总金额">
          <el-input v-model="invoice.invoiceDetailList[0].invTotalAmount"></el-input>
        </el-form-item>
        <el-form-item label="高速标志">
          <el-input v-model="invoice.invoiceDetailList[0].highwayFlag"></el-input>
        </el-form-item>
      </template>
      <template v-else>
        不支持的发票类型
      </template>
    </template>
  </el-form>
</template>

<script>
import request from "../../utils/request";

export default {
  name: "Image_Invoice",
  props: {
    imageData: Object,
    invoiceId: Number
  },
  data() {
    return {
      invoice: {
        invNumber: '',
        invCode: '',
        invDate: '',
        invType: '',
        invAmount: '',
        invTax: '',
        invTotalAmount: '',
        invoiceAddress: '',
        companyName: '',
        companyTaxCode: '',
        vendorName: '',
        vendorTaxCode: '',
        invoiceDetailList: []
      },
    }
  }, methods: {
    findInvoiceById(id) {
      return request({
        url: `/invoice/edocInvoice/findById/` + id,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8092'
      }).then(result => {
        this.invoice = result.data
      })
    }
  }, created() {
    if (this.invoiceId) {
      this.findInvoiceById(this.invoiceId)
    }
  }
}
</script>

<style scoped>
.el-date-editor.el-input {
  width: 100%;
}
</style>
